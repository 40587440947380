
























import { Component, Vue } from 'vue-property-decorator';
import MundoBancanteHeader from '../components/MundoBancante/MundoBancanteHeader.vue';
import TextMedium from '../components/Text/TextMedium.vue';
import InputText from '../components/Input/InputText.vue';
import Api from '../helpers/Api';
import Button from '../components/Button/Button.vue';
import montoValidation from '../utils/validations';
import Config from '../helpers/Config';

@Component({
  components: {
    MundoBancanteHeader,
    TextMedium,
    InputText,
    Button,
  },
})
export default class Monto extends Vue {
  private logged = false;

  private monto = '';

  private payment = '[CARGANDO]';

  private montoActual = '[CARGANDO]';

  private error = '';

  private buttonText = 'Enviar';

  private hasAnnouncementGift = true;

  private specialGiftAmount = Config.SpecialGiftAmount;

  private specialGiftAmountUSD = Config.SpecialGiftAmountUSD;

  async onSubmit() {
    if (!this.logged || this.payment === '[CARGANDO]') return;
    const montoVal = montoValidation(Number(this.monto), this.payment);
    if (!montoVal.status) {
      this.error = montoVal.message;
      return;
    }

    if (this.monto.trim() === this.payment) {
      return;
    }

    const token = this.$store.state.accessToken;

    const bodyFormData = new FormData();
    bodyFormData.append('creator_id', '1');
    bodyFormData.append('monto', this.monto);

    const override = localStorage.getItem('mundobancante-override');
    if (override) bodyFormData.append('override', override);

    this.error = '';
    this.buttonText = 'Cargando...';

    try {
      const response = await fetch(`${Api.sendMontoMail}`, {
        method: 'POST',
        body: bodyFormData,
        headers: { token: `Bearer ${token}` },
      });

      if (response.ok) {
        const data = await response.json();

        const hasGift = (
          !this.hasAnnouncementGift
                && (

                  (
                    this.payment !== 'paypal'
                  && +this.montoActual < this.specialGiftAmount
                  && +this.monto >= this.specialGiftAmount
                  )
                  || (
                    this.payment === 'paypal'
                  && +this.montoActual < this.specialGiftAmountUSD
                  && +this.monto >= this.specialGiftAmountUSD
                  )

                )
        ) ? 'true' : 'false';

        if (data.result === 'success') {
          this.$router.push(
            {
              name: 'Montocambiado',
              query: {
                gift: hasGift,
              },
            },
          );
        }
      } else {
        const data = await response.json();
        if (!data) throw new Error('Network response was not ok');
        this.error = `${data.messages.error}`;
        if (this.error.indexOf('monto válido') === -1) {
          this.error = `${this.error}. Contactanos para revisarlo manualmente escribiendo a: bancan@elgatoylacaja.com`;
        }
      }
    } catch (e) {
      console.error(e);
      this.buttonText = 'Enviar';
      this.error = 'Ocurrió un error en el servidor. Por favor envianos un mail a estamosaprendiendo@elgatoylacaja.com para que podamos arreglarlo.';
    }
  }

  async created() {
    const token = this.$store.state.accessToken;

    const bodyFormData = new FormData();
    bodyFormData.append('creator_id', '1');

    const override = localStorage.getItem('mundobancante-override');
    if (override) bodyFormData.append('override', override);

    try {
      const [subsDataResponse, giftGetResponse] = await Promise.all([
        fetch(`${Api.subscriptionGet}`, {
          method: 'POST',
          body: bodyFormData,
          headers: { token: `Bearer ${token}` },
        }),
        fetch(`${Api.giftGet}`, {
          method: 'POST',
          body: bodyFormData,
          headers: { token: `Bearer ${token}` },
        }),
      ]);

      if (!subsDataResponse.ok || !giftGetResponse.ok) {
        throw new Error('Network response was not ok');
      }

      const [subsData, giftGet] = await Promise.all([
        subsDataResponse.json(),
        giftGetResponse.json(),
      ]);

      this.montoActual = subsData.transaction_amount;
      this.payment = subsData.payment_provider;

      this.hasAnnouncementGift = !!giftGet.find((x: any) => x.name === 'Libro de regalo');

      this.logged = true;
    } catch (e) {
      console.error(e);
      this.$router.push({ name: 'Home' });
    }
  }
}
